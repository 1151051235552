import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { RxHamburgerMenu } from "react-icons/rx";
import { FaArrowRight } from "react-icons/fa6";

import { cnt, images, urls, pages } from './content.jsx';

import './index.css';

import axios from "axios";

axios.defaults.headers['Content-Type'] = 'application/json'

const origin = window.location.origin.includes('localhost') ? window.location.origin.slice(0, -5) + ':8000' : window.location.origin;

const Header = (props) => {
    const [isVisible, setIsVisible] = useState(false);

    // Step 2: Function to toggle visibility
    const toggleVisibility = () => {
      setIsVisible(!isVisible);
    };

    return <div className='home-header'>
        <div className='home-header-left'>
            <div className='header-hamburger-wrapper'>
            <div className='header-hamburger' onClick={ toggleVisibility }><RxHamburgerMenu /></div>
                <a href={urls.home}>
                    <div className='logo-cell'>
                        <img width='200' src={ images.merelogicLogo } />
                    </div>
                </a>
            </div>
            <div className='home-header-cells-wrapper' style={ isVisible ? { display: 'block' } : null }>
                <div className='home-header-cells'>
                    { cnt.template.headerCells.map((c) =>
                        <a href={ c.url } key={ c.url }>
                            <div className='home-header-cell'>{ c.display }</div>
                        </a>)}
                </div>
            </div>
        </div>
        <div className='home-header-right'>
            <a href={ cnt.template.bookACallButton.url }>
                <div className='home-button'>
                    { cnt.template.bookACallButton.display }
                </div>
            </a>
        </div>
    </div>
}

const Footer = (props) => {
    return <div className='home-footer'>
        <a href={urls.home}>
            <div className='logo-cell'>
                <img width='200' src={ images.merelogicLogo } />
            </div>
        </a>
        <div className='home-footer-links'>
            { cnt.template.footerLinks.map((l) => (
                <a href={ l.url } key={ l.url }><div className='home-footer-link'>{ l.display }</div></a>
            ))}
        </div>
    </div>
}

const HeroBlock = (props) => {
    return <div className='hero-outer'>
        <img className='hero-image' width='1000' src={ images.heroBackground } />
        <div className='hero-inner'>
            <div className='hero-left'>
                { cnt.home.heroLeft }
            </div>
            <a href={urls.whoWeServe}>
            <div className='hero-right'>
                <div className='hero-title'>
                    { cnt.home.heroRightHeading }
                </div>
                <div className='hero-subtitle'>
                    { cnt.home.heroRightText }
                </div>
            </div>
            </a>
        </div>
    </div>
}

const ViralEsotericaCallout = (props) => {
    return <div className='home-block'>
        <div className='home-callout'>
            <div className='home-callout-left'>
                { cnt.home.veCalloutText }
            </div>
            <div className='home-callout-right'>
                <a href={ cnt.home.veCalloutButton.url }>
                    <div className='home-button'>
                        { cnt.home.veCalloutButton.display }
                    </div>
                </a>
            </div>
        </div>
    </div>
}

const HomeBlocks = (props) => {
    return <div className='home-block'>
        <div className='boxes-outer'>{
            cnt.home.boxes.map((b) => (
                <a href={ b.url }>
                    <div className='boxes-cell'>
                        <img className='box-image' src={b.image} />
                        <div className='box-text'>
                            { b.title }
                        </div>
                    </div>
                </a>
            ))
        }
        </div>
    </div>
}

const PageWrapper = (contents) => {
    return <div className='home-outer'>
        <div className='home-limiter'>
            <Header />
            { contents }
            <Footer />
            <div className='bottom-buffer'> </div>
        </div>
    </div>

}

const HomePagePage = (props) => {
    return PageWrapper(<div>
        <HeroBlock />
        <ViralEsotericaCallout />
        <HomeBlocks />
        <div className='horizontal-buffer' />
    </div>)
}

const sectionColors = {
    default: '#ffffff',
    outer: '#C2CFD6',
}

const SectionBody = (c) => {
    return <div>
        <div className='paper-body'>
            { c.body }
        </div>
        { c.links ? c.links.map((l) => (
            <div key={l.text} className='paper-body'><a href={l.url}>{l.text} <FaArrowRight size={24} /></a></div>)) : null
        }
    </div>
}

const splitHeading = (text) => {
    if (text.includes(' | ')) {
        return text.split(' | ').map((t) => <div>{ t }</div>)
    } else {
        return text
    }
}

const SplitBlock = (c) => {
    return <div className='split-outer'>
        <div className='split-heading'>
            <div className='paper-heading'>{ splitHeading(c.heading) }</div>
        </div>
        <div className={'split-body'}>{ SectionBody(c) }</div>
    </div>
}

const ColumnImage = (c) => {
    return c.image ? (
        c.imageUrl ? <a href={ c.imageUrl }><div><img className='column-image' src={ c.image } /></div></a> :
        <div><img className='column-image' src={ c.image } /></div>
    ) : null
}

const Column = (c) => {
    return <div className={`column-outer-${c.type}`}>
        <div className={`column-inner-${c.type}`}>
            { ColumnImage(c) }
            <div className='column-text'>
                <div className='paper-heading'>{ c.heading }</div>
                { SectionBody(c) }
            </div>
        </div>
    </div>
}

const formStates = {
    form: 'FORM',
    submitted: 'SUBMITTED',
    done: 'DONE',
    failed: 'FAILED',
}

const Form = (c) => {
    const [submitted, setSubmitted] = useState(formStates.form);
    const [formValues, setFormValues] = useState({
        'Full Name': '',
        'Email': '',
        'Company': '',
        'Product': '',
        'Comments': '',
        'Form': 'NOAI Report Card',
    });

    const formField = (field) => (
        <input
            className='form-field'
            value={ formValues[field] }
            onChange={ (event) => setFormValues(prevState => ({ ...prevState, [field]: event.target.value })) }
        />
    )

    const submitRequest = () => {
        axios
            .post(`${origin}/opt_in/`, formValues)
            .then(() => setSubmitted(formStates.done))
            .catch(() => setSubmitted(formStates.failed));
        setSubmitted(formField.submitted)
    }

    if (submitted === formStates.form || submitted === formStates.failed) {
        return <div className='form-outer'>
            { submitted === formStates.failed ? <div className='form-fail'>
                Something went wrong when submitting your request. Please try again and contact
                jesse@merelogic.net if the problem persists.
            </div> : null}
            <div className='form-heading'>{ c.heading }</div>
            <div className='form-row'>
                <div className='form-box'>
                    <div className='form-label'>Full Name</div>
                    { formField('Full Name') }
                </div>
                <div className='form-box'>
                    <div className='form-label'>Work Email</div>
                    { formField('Email') }
                </div>
            </div>
            <div className='form-row'>
                <div className='form-box'>
                    <div className='form-label'>Company Name</div>
                    { formField('Company') }
                </div>
                <div className='form-box'>
                    <div className='form-label'>Product Name (if different)</div>
                    { formField('Product') }
                </div>
            </div>
            <div className='form-box'>
                <div className='form-label'>Comments</div>
                <div className='form-row'>
                    <textarea
                        className='form-field text-box'
                        value={ formValues.Comments }
                        rows='6'
                        cols='50'
                        onChange={ (event) => setFormValues(prevState => ({ ...prevState, Comments: event.target.value })) }
                    />
                </div>
            </div>
            <div className='submit-outer'><div className='home-button' onClick={ submitRequest }>Request</div></div>
        </div>
    } else if (submitted === formStates.done) {
        return <div className='form-outer'>
            <div className='form-final-outer'>
                <div className='form-final'>Thank you for requesting an AI Narrative Report Card</div>
                <div className='form-final'>We'll reach out shortly with more information and an estimate of when your report will be ready.</div>
            </div>
        </div>
    } else {
        return <div className='form-outer'>
            <div className='form-final-outer'>
                <div className='form-final'>Your request is being submitted...</div>
                <div className='loader'> </div>
            </div>
        </div>
    }
}

const Product = (c) => {
    return <div className='product-outer'>
        <div className='product-inner'>
            <div className='product-heading'>
                { c.heading }
            </div>
            <div className='product-body'>
                { c.descr }
            </div>
            <div className='product-subheading'>
                What it's for:
            </div>
            <div className='product-body'>
                { c.for }
            </div>
            <div className='product-subheading'>
                Deliverables:
            </div>
            <div className='product-body'>
                <ul>
                    { c.deliverables.map((d, i) => <li key={ i }>{ d }</li>)}
                </ul>
            </div>
            <div className='product-subheading'>
                Price:
            </div>
            <div className='product-body'>
                { c.price }
            </div>
            { c.links ? c.links.map((l) => (
            <div key={l.text} className='paper-body'><a href={l.url}>{l.text} <FaArrowRight size={24} /></a></div>)) : null
            }

        </div>
    </div>
}

const sectionBuilders = {
    split: SplitBlock,
    left: Column,
    right: Column,
    form: Form,
    product: Product,
}

const ParagraphBlocks = (props) => {
    return <div>
        <div className='paragraphs-outer'><div className='paragraphs-inner'>
            {
            props.content.map((c, i) => (
                <div key={ i} className='section-outer' style={{ backgroundColor: sectionColors[c.color ? c.color : 'default']}}>
                    { sectionBuilders[c.type](c) }
                </div>
            ))
        }</div></div>
    </div>
}

const ParagraphPage = (props) => {
    return PageWrapper(<div>
        <ParagraphBlocks content={ props.page }/>
    </div>)
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <Router>
            <Routes>
                <Route path={'/'} element={<HomePagePage />} />
                { pages.map((p) => <Route path={ p.url } key={ p.url } element={<ParagraphPage page={ p.page } />} /> )}
            </Routes>
        </Router>
    </React.StrictMode>
);
