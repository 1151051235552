const urlBase = '.'

export const baseUrls = {
    home: '',
    emergingTechNiches: 'etn',
    whoWeServe: 'serve',
    whatWeOffer: 'offer',
    ourWork: 'portfolio',
    whoWeAre: 'who',
    whatIsETN: 'etn',
    reportCard: 'report_card',
    noai: 'noai',
}

export const intUrls = Object.fromEntries(
    Object.entries(baseUrls).map(([k, v]) => ([k, `${urlBase}/${v}`]))
)

export const urls = intUrls;

const baseImages = {
    merelogicLogo: 'merelogic_text.svg',
    heroBackground: 'hero_left.png',
    refStack: 'ref_stack.png',
    contentMarketingBox: 'content_marketing_box.png',
    scalingBiotechBox: 'scaling_biotech_box.png',
    scalingBiotech: 'scaling_biotech.png',
    leadingTeams: 'leading_teams.png',
    aiSafe: 'ai_safe.png',
    unblocking: 'unblocking.png',
    jesse: 'profile_jesse.png',
    vijay: 'profile_vijay.jpg',
    vicky: 'profile_vicky.jpg',
}

export const images = Object.fromEntries(
    Object.entries(baseImages).map(([k, v]) => ([k, `./assets/${v}`]))
)

export const extUrls = {
    bookADiscoveryCall: 'https://airtable.com/appuSDJXGmy5wXYzx/pagF2yfCB7ldmMW6c/form',
    viralEsoterica: 'https://viralesoterica.substack.com/',
    aiSafePipelines: 'https://ptp.cloud/building-ai-safe-biotech-data-pipelines/',
    refStack: 'https://stack.merelogic.net/',
    contentMarketing: 'https://airtable.com/appuSDJXGmy5wXYzx/pag5LS0qUA5XcuEj9/form',
    scalingBiotech: 'https://scalingbiotech.substack.com/',
    leadingTeams: 'https://saturncloud.io/resources/Leading_Biotech_Teams.pdf',
    unblocking: 'https://drive.google.com/file/d/15-bdoJbBvyGsJtvCIweNbO0NGGOOwbbO/view?usp=sharing',
}

export const cnt = {
    template: {
        headerCells: [
            { display: 'Who we serve', url: intUrls.whoWeServe },
            { display: 'What we offer', url: intUrls.whatWeOffer },
            { display: 'Our work', url: intUrls.ourWork },
            { display: 'Who we are', url: intUrls.whoWeAre },
        ],
        bookACallButton: {
            display: `Book a discovery call`,
            url: extUrls.bookADiscoveryCall,
        },
        footerLinks: [
            { display: 'Contact Us', url: urls.home },
            { display: 'Book a discovery call', url: extUrls.bookADiscoveryCall },
            { display: 'Portfolio', url: urls.ourWork },
            { display: 'Who we are', url: urls.whoWeAre },
        ],
    },


    home: {
        // heroLeft: 'Merelogic helps software startups in emerging technical niches reach the customers who need them.'
        // heroLeft: `The key to building trust with customers is to help them understand their problems before they're even customers.`,
        heroLeft:
`Differentiating your software in an established market is hard.

In a market disrupted by AI, it's even harder.`,
        // heroRightHeading: `What is an Emerging Technical Niche?`,
        heroRightHeading: `Want to stand out?`,
        heroRightText:
`Learn how Merelogic can help you build trust and goodwill with prospective customers while communicating what makes you different.`,
        // heroRightText: `Learn how content marketing in emerging technical niches is different.`,
        veCalloutText: 'We share all our tricks on a weekly newsletter:',
        veCalloutButton: {
            display: 'Read and subscribe on Substack',
            url: extUrls.viralEsoterica
        },
        boxes: [
            {
                title: 'The Biotech Reference Stack',
                url: extUrls.refStack,
                image: images.refStack,
            }, {
                title: 'Content Marketing for Emerging Technical Niches',
                url: extUrls.contentMarketing,
                image: images.contentMarketingBox,
            }, {
                title: 'Scaling Biotech',
                url: extUrls.scalingBiotech,
                image: images.scalingBiotechBox,
            },
        ],
    },


    whoWeServe: [
        {
            type: 'split',
            color: 'outer',
            heading: 'Who we serve',
            body:
`Merelogic helps deep tech software startups in emerging technical niches reach the customers who need them.`
        }, {
            type: 'left',
            heading: 'Emerging Technical Niches',
            body:
`An emerging technical niche is a business sector where data/AI is fundamentally changing how teams work, creating the need for specialized, domain-specific tools.

These changes typically happen faster than stakeholders can keep up. So instead of seeing new opportunities and clear requirements, they only see vague, looming problems. Problems that they don't have words for. Problem for which they don't know where to start looking for solutions.`
        }, {
            type: 'right',
            heading: `Building for the future`,
            body:
`Merelogic's clients are software companies building tools to address the underlying causes of the vague, looming problems in emerging technical niches. They're two steps ahead of their target customers in understanding these problems. And they've built solutions that will work today and into the future.`
        }, {
            type: 'left',
            heading: `The Bottleneck isn't Solutions`,
            body:
`In an emerging technical niche, it isn't enough to build a better mousetrap. If you want your target customers to find you, you have to first show them where to look. Help them understand the underlying causes and mechanics of their looming problems. Show them what the possible outcomes look like. Then convince them a solution is possible.`
        }, {
            type: 'right',
            heading: `Driving Change`,
            body:
`Merelogic's clients don't just build solutions. They teach their target customers how to understand their problems. To show them what good really looks like. To lead the market to where it needs to go.`
    }, {
        type: 'left',
        heading: `Customers aren't looking`,
        body:
`In established software niches, stakeholders know what solutions look like, where to find potential solutions and how to evaluate them. In emerging technical niches, most stakeholders don't even have words for the problem. So they're multiple steps away from looking for solutions. And they're definitely not looking for yours.`
    }, {
        type: 'right',
        heading: `Reaching the customers who need you`,
        body:
`Merelogic's clients know that to reach stakeholders in emerging technical niches, they need their target customers to spread the word. Instead of buying ads or gaming SEO, they create social posts, blogs, newsletters and other resources that genuinely help their readers begin to solve their big, looming problems. Those customers share the content with their friends and colleagues because they know they need it.

By helping their target customers understand their problems, Merelogic's clients lead them down the path to understanding solutions.`
    }, {
            type: 'split',
            color: 'outer',
            heading: 'Looking for help?',
            links: [{
                url: `./${baseUrls.whatWeOffer}`,
                text: 'Learn how Merelogic can help you.'
            }],
            body:
`Merelogic helps deep tech software startups in emerging technical niches reach the customers who need them.`
        },
    ],


    whatWeOffer: [
        {
            type: 'split',
            color: 'outer',
            heading: 'What we offer',
            body:
`Merelogic provides content marketing and go-to-market strategy services for software startups in emerging technical niches.`
        }, {
            type: 'left',
            heading: `Your target customers need your insights`,
            body:
`In an emerging technical niche, the tool builders have the broadest and clearest understanding of the their target customers' looming problems. You've seen patterns across multiple teams and organizations. You've thought about wholistic solutions instead of fire fighting.

But if you want your target customers to adopt those solutions, you need them to understand these same insights.`
        }, {
            type: 'right',
            heading: `We help you organize them`,
            body:
`The hard part is distilling this deep and complex technical knowledge into simple, bite sized pieces that your target customers will understand, appreciate and learn from. Merelogic uses a proven framework to organize our clients' insights into a form that's optimized for sharing.`
        }, {
            type: 'left',
            heading: 'You need Their Trust',
            body:
`If you can get it right, your target customers will see you as more than just a tool builder. You'll become a trusted source of information. Once they recognize that you understand their problems better than they do, they'll trust you to make software that solves those problems.`
        }, {
            type: 'right',
            heading: `We help you build it`,
            body:
`Merelogic helps software startups build this trust through targeted and consistent content that readers will find insightful enough to share. We can help you define an overall strategy, then outline and write individual pieces of content from social posts to blogs and newsletters.`
        }, {
            type: 'left',
            heading: 'You need a Plan',
            body:
`To begin creating content that builds trust with your target customers, you need four things:

Positioning: How you describe what you do and the problems you solve.

Topics: Cleanly scoped ideas that provide value while building a story around your positioning.

Framing: A compelling way to organize and communicate the ideas behind each topic.

Words: Content that clearly and compellingly communicates the topic and framing.`
        }, {
            type: 'right',
            heading: `We've got you covered`,
            body:
`Merelogic's approach uses a framework called Core Stories to determine the Positioning, Topics and Framing that makes sense for each of our clients. Then we work with clients to write the Words, either writing the content or providing feedback on what our clients have written.`
    }, {
            type: 'split',
            color: 'outer',
            heading: 'Ready to get started?',
            links: [{
                url: extUrls.contentMarketing,
                text: 'Learn about the Core Stories framework.'
            }, {
                url: `./${baseUrls.ourWork}`,
                text: `See the work we've done.`,
            }],
            body:
`Merelogic can help you create content that gets your target customers' attention, builds trust and teaches them what they need to know.`
        }
    ],


    ourWork: [
        {
            type: 'split',
            color: 'outer',
            heading: 'Our Work',
            body:
`Content that Merelogic has produced.`
        }, {
            type: 'left',
            heading: 'Scaling Biotech',
            links: [{
                url: extUrls.scalingBiotech,
                text: 'Check it out.'
            }],
            image: images.scalingBiotech,
            imageUrl: extUrls.scalingBiotech,
            body:
`Merelogic's founder, Jesse Johnson, writes this weekly newsletter about data management in early discovery biotech. Despite the esoteric nature of this topic, the newsletter has over 1,200 subsribers with a consisten open rate over 40%. Jesse has been accused of hiding microphones in his readers' labs because he regularly covers topics that they were just talking about.`
        }, {
            type: 'right',
            heading: 'Leading Biotech Data Teams',
            links: [{
                url: extUrls.leadingTeams,
                text: 'Check it out.'
            }],
            image: images.leadingTeams,
            imageUrl: extUrls.leadingTeams,
            body:
`Merelogic's founder, Jesse Johnson, wrote this O'Reilly report in partnership with Saturn Cloud.`
        }, {
            type: 'left',
            heading: 'Building AI-safe Biotech Data Pipelines',
            links: [{
                url: extUrls.aiSafePipelines,
                text: 'Check it out.'
            }],
            image: images.aiSafe,
            imageUrl: extUrls.aiSafePipelines,
            body:
`This collaboration between Merelogic and PTP Cloud explores how the requirements for data pipelines has changed as a result of AI.`
        }, {
            type: 'right',
            heading: 'Unblocking Biotech Data Teams',
            links: [{
                url: extUrls.unblocking,
                text: 'Check it out.',
            }],
            image: images.unblocking,
            imageUrl: extUrls.unblocking,
            body:
`This white paper by Merelogic's founder, Jesse Johnson, defines a framework for designing data infrastructure for early stage discovery biotech in the context of computational biology, data science and AI/ML teams.`
        }, {
            type: 'split',
            color: 'outer',
            heading: 'We want to do the same for you',
            links: [{
                text: `Book a discovery call`,
                url: extUrls.bookADiscoveryCall,
            }, {
                url: `./${baseUrls.whoWeAre}`,
                text: `See who you'll be working work.`,
            }],
            body:
`Get started today, creating content that will reach your target customers.`
        },
    ],


    whoWeAre: [
        {
            type: 'split',
            color: 'outer',
            heading: 'Who We Are',
            body:
`Merelogic partners with independent experts from multiple industries to offer a wide scope of services to help deep tech software startups in emerging technical niches reach the customers who need them.`
        }, {
            type: 'right',
            heading: 'Jesse Johnson',
            links: [],
            image: images.jesse,
            body:
`Founder and CEO

Jesse has been working on internal software teams in Life Science/Biotech since 2016 for companies including Verily Life Sciences, Sanofi, Cellarty and Dewpoint Therapeutics. His deep understanding of biotech’s organizational dynamics, technical problems and solution landscape allow Merelogic to address the unique quirks of the Biotech space.`
    }, {
        type: 'right',
        heading: 'Vijay Renganathan',
        image: images.vijay,
        links: [],
        body:
`SaaS Go-to-Market

Vijay is an experienced marketing and pre-sales/solutions engineering leader, with over a decade in SaaS (and a lifelong enthusiasm for science, including an undergrad internship with WGBH NOVA). With experience from startups to Salesforce, supporting specialized audiences – martech analytics, sales enablement, private aerospace, and more – Vijay is skilled in translating core positioning principles to audience-aware messaging. His deep understanding of sales, marketing and pre-sales engineering brings a perspective that helps reframe biotech-specific pain into commercial go-to-market action.`
    }, {
        type: 'right',
        heading: 'Vicky Li Horst',
        image: images.vicky,
        links: [],
        body:
`Product Strategy

Vicky has extensive hands-on experience in product management, learning to apply tech best practices to scientific products during her time leading product teams at the Broad Institute of MIT and Harvard. With a background in bioinformatics and a decade of experience in biotech/healthcare, she understands the nuances of the field, enabling her to efficiently help biotech/healthcare organizations with their product management challenges.`
    }, {
        type: 'split',
        color: 'outer',
        heading: 'Get started today',
        links: [{
            url: extUrls.bookADiscoveryCall,
            text: 'Schedule a discovery call.'
        }],
        body:
`Reach out to learn how Merelogic can help you reach the customers who need you.`
        }
    ],


    reportCard: [
        {
            type: 'split',
            color: 'outer',
            heading: ' AI Narrative | Report Card',
            body:
`A free, custom report evaluating the likelihood that AI chatbot answers about your product will support your intended narrative`
        }, {
            type: 'left',
            heading: 'The new Search',
            body:
`Decision makers are increasingly leveraging AI chatbots such as ChatGPT, Copilot, Gemini and Claude during B2B SaaS discovery and evaluation. When they ask these chatbots about your product, it's important that the answers are not only accurate, but also reflect the positioning and narrative that you've worked so hard to develop.`
        }, {
            type: 'right',
            heading: 'Take back control',
            body:
`You can manage how AI chatbots answer questions about your product. But the tools are different from what's worked in the past.`
        }, {
            type: 'left',
            heading: 'Evaluation first',
            body:
`Before you can start wresting control of your narrative back from AI, you need to understand where your stand today.`
        }, {
            type: 'right',
            heading: 'Get started today',
            body:
`Merelogic's AI Narrative Report Card provides a thorough overview of how accurately AI chatbots are able to answer questions about your product.

It's the fastest way to get started.

And it's free.`
        }, {
            type: 'form',
            heading: 'Request a report for your B2B SaaS',
        }
    ],

    noai: [
        {
            type: 'split',
            color: 'outer',
            heading: ' Narrative | Optimization | for AI',
            links: [{
                text: `Request a Free AI Narrative Report Card`,
                url: intUrls.reportCard,
            }],
            body:
`Decision makers are using AI chatbots to identify and evaluate B2B SaaS tools.

Is your marketing ready?`
        }, {
            type: 'left',
            heading: 'Losing grip on the narrative',
            body:
`You can't control what AI chatbots say about you, at least not directly.

But that doesn't stop decision makers from trusting them.

Inaccurate answers set false expectations. Narratives that don't match your positioning create confusion.

Your ideal customers decide you're a bad fit before you've gotten a chance to win them back.`
        }, {
            type: 'right',
            heading: `It's time to take back control`,
            body:
`What if you could identify inaccurate answers before users saw them?

What if you could trace each mistake to a specific root cause?

What if you could fix it so the answer was never wrong again?`
        }, {
            type: 'left',
            heading: `It's within your grasp`,
            body:
`AI chatbots like ChatGPT use web searches to find information for their answers.

So you can influence them by publshing the information they need to create clear and accurate answers.

The trick is anticipating the questions, so you can publish the information that drives the answers.`
        }, {
            type: 'right',
            heading: 'Merelogic will help',
            body:
`Merelogic's experts will determine the questions that your target customers are most likely to ask across a range of categories.

Then we'll help you monitor the answers on all major AI platforms.

And when the answers don't match your target narrative, we'll trace it back to missing or confusing information on your website, and recommend actionable changes.`
        }, {
            type: 'left',
            heading: 'Get Started Today',
            links: [{
                text: `Request a Free AI Narrative Report Card`,
                url: intUrls.reportCard,
            }],
            body:
`Merelogic's Narrative Optimization for AI (NOAI) monitoring will help you find the gaps and decide where to start.

We recommend B2B SaaS companies start with our (Free) AI Narrative Report Card to get a high-level understanding of their situation.

Then, when you're ready to dig deeper, the Tailored AI Narrative Report will give you detailed insights into AI answers to specific, high-priority questions.`
        }, {
            type: 'right',
            heading: 'And get set for the future',
            body:
`Finally, to make sure you can quickly adapt in the long run, we offer two options:

If you're overall happy with the AI narrative, but want to make small adjustments and/or monitor for future changes, our NOAI Monitoring package will provide monthly updates and recommendations.

But if bigger changes are in order, our Development Support plan offers detailed, ongoing feedback both before and after you publish.`
        }, {
            type: 'split',
            color: 'outer',
            heading: 'Services',
            body: ``
        }, {
            type: 'product',
            heading: '(Free) AI Narrative Report Card',
            descr:
`Get a high-level overview of how AI chatbots answer questions about your B2B SaaS. See how you compare to industry peers/competitors, both when potential customers look for options and when they evaluate your product.`,
            price: `Free`,
            deliverables: [
                'A summary report based on publicly available information'
            ],
            for:
`Deciding if Narrative Optimization for AI is right for you.`,
            links: [{
                text: `Request a Report Card`,
                url: intUrls.reportCard,
            }],
        }, {
            type: 'product',
            heading: 'Tailored AI Narrative Report',
            descr:
`Get a detailed understanding of whether AI chatbots provide accurate answers, consistent with your positioning, to the specific kinds of questions that your target customers are most likely to ask. Define the amount and nature of work needed to bring them closer to your desired positioning.`,
            price: `$5,000`,
            deliverables: [
                `List of questions your target customers are likely to ask AI, specific to your product and competitive landscape`,
                `Target answers and evaluation criteria for each question`,
                `Report on how answers from the major AI engines (including ChatGPT, Copilot, Gemini and Claude) compare to your target answers`,
                `A one-hour consultation call to answer questions and clarify recommendations`,
            ],
            for:
`Determining where your AI Narrative stands today and the amount of effort required to get where you want to be.

Do you need major changes to fix how AI chatbots answer questions, or can you just monitor the situation?`
        }, {
            type: 'product',
            heading: 'Monthly NOAI Monitoring',
            descr:
`Get regularly updated recommendations on how to keep AI answers aligned with your target positioning, as your product, the AI chatbots and NOAI best practices evolve.`,
            price: `$7,000/quarter or $25,000/year`,
            deliverables: [
                `A monthly monitoring report with updated evaluations of the answers from the major AI engines`,
                `Updated recommendations based on any changes to the monitoring report and any changes to the state of AI and competitive best practices`,
            ],
            for:
`Monitoring your AI reputation to identify minor adjustments you can make while anticipating any major changes you'll need to make in the future`
        }, {
            type: 'product',
            heading: 'NOAI Development Support',
            descr:
`Get quick, detailed feedback on draft changes to your content, as you make them, so you can tailor updates to a deliberate strategy.`,
            price: `$20,000/quarter or $70,000/year`,
            deliverables: [
                `Everything in the Monthly NOAI Monitoring option`,
                `Updated reports on draft changes to your website up to twice a week`,
                `Biweekly review meetings with updated feedback and recommendations`,
                `Up to 4 ad hoc feedback/strategy discussions per month`,
            ],
            for:
`Ensuring that significant changes to your marketing content, aimed at improving AI answers, have the intended effect before you publish`
        }, {
            type: 'split',
            color: 'outer',
            heading: 'Get Started Today',
            links: [{
                text: `Request a Free AI Narrative Report Card`,
                url: intUrls.reportCard,
            }],
            body:
`It doesn't matter if you use AI.
It matters that your potential customers use it.

Is your marketing ready?`
        }
    ],
}

export const pages = [
    { url: baseUrls.whoWeServe, page: cnt.whoWeServe },
    { url: baseUrls.whatWeOffer, page: cnt.whatWeOffer },
    { url: baseUrls.ourWork, page: cnt.ourWork },
    { url: baseUrls.whoWeAre, page: cnt.whoWeAre },
    { url: baseUrls.reportCard, page: cnt.reportCard },
    { url: baseUrls.noai, page: cnt.noai },
]